ul.pagination {
  list-style: none;
  li.page-item {
    display: inline-block;
    a {
      padding: 0.05em 0.4em;
    }
    &.active {
      a {
        color: $navigation-color;
        border-bottom: 3px solid $navigation-color;
      }
    }
  }
}

.navigation {
  max-width: calc(100% - 0px);
  margin: 0 auto;
  margin-top: 60px;
  a {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    color: $white;
    background-color: $navigation-color;
    border-radius: 10px;
    line-height: 1.25;
    text-transform: uppercase;
    &:hover {
      padding: 14px;
      background-color: lighten($navigation-color,10%);
    }
    &:nth-child(2) {
      margin-top: 10px;
    }
    &:first-child:last-child {
      width: 100%;
    }
  }
  .fa {
    font-size: 0.8rem;
  }
  .navigation-prev {
    text-align: left;
    .fa {
      padding-right: 10px;
    }
    .navigation-title {
      padding-left: 4px;
      color: $white;
    }
    .navigation-indicator {
      padding-right: 10px;
      font-size: 1.6rem;
      text-align: bottom;
      color: $white;
    }    
  }
  .navigation-next {
    text-align: right;
    .fa {
      padding-left: 10px;
    }
    .navigation-title {
      padding-right: 4px;
      color: $white;
    }
    .navigation-indicator {
      padding-left: 10px;
      font-size: 1.6rem;
      color: $white;
    }
  }
}
.navigation-single a {
  text-transform: none;
}
